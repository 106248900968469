.photo-video-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 150px;
    margin-right: 12px;
    border-radius: 16px;
    background-color: #6A6D6D;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.spinner {
    position: absolute;
}

.photo-add-btn {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: #2C2B7C;
}

.photo-remove-fab {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    background-color: #2c2b7c;
    color: #FFFFFF;
    border-radius: 50%;
}
