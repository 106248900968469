.settings-container {
    background-color: #F3E7D0;
    width: 85%;
    height: 100%;
    padding: 0;
}

.settings-col {
    font-family: 'Muli Bold', sans-serif;
    width: 100%;
    font-size: 20px;
    border-bottom: 1px solid #A3CF62;
    padding: 10px 0;
    margin-top: 30px;
    height: 50px;
}

.settings-card {
    width: 85%;
    min-height: 100px;
    border-radius: 20px;
    margin-top: 10px;
}

.settings-card-title {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 25px;
}

.settings-link-btn {
    font-family: 'Poppins Medium', sans-serif;
    text-decoration: underline;
    cursor: pointer;
}

.current-card-details {
    font-family: 'Poppins Medium', sans-serif;
    font-size: 14px;
    color: #2C2B7C;
    height: auto;
}

#save-card-btn {
    margin-top: 15px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 40px;
    width: 150px;
    font-family: 'Poppins Bold', sans-serif;
    font-weight: bold;
}


.delete-acc-modal {
    max-width: 500px;
}

.modal-body-container {
    width: 100%;
    height: 100%;
}

.delete-acc-modal > .modal-content {
    height: 200px !important;
    width: 100% !important;
    border-radius: 28px;
    padding: 10px;
}

.delete-reasons-modal > .modal-content {
    height: auto !important;
    width: 100% !important;
    border-radius: 28px;
    padding: 10px;
}

.delete-acc-header {
    font-family: 'Poppins Semi Bold', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #2c2b7c;
}

.delete-nn-btn {
    font-family: 'Poppins Bold', sans-serif;
    color: #FFFFFF;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 150px;
}

.delete-confirmation-btn {
    font-family: 'Poppins Bold', sans-serif;
    border: 1px solid #2c2b7c !important;
    border-radius: 33px;
    background-color: transparent;
    color: #2c2b7c;
}

.delete-acc-btn {
    font-family: 'Poppins Bold', sans-serif;
    color: #FFFFFF;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 200px;
}

.delete-acc-checkbox > .form-check-input {
    width: 18px;
    height: 18px;
    background-color: #2c2b7c;
}

#change-submit-btn {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 200px;
}
