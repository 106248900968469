.footer {
    background-image: url("../../assets/bottomBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 80% 60%;
    width: 100%;
    height: 60%;
    background-color: #F3E7D0;
}

.legal-info-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
    line-height: 1 !important;
    background-color: #A3CF62;
}

.legal-wrapper {
    width: 800px;
    height: 20px;
}

.rights-reserved-lbl {
    font-family: 'Muli Bold', sans-serif;
    color: #2c2b7c;
    font-size: 14px;
}

.legal-external-links {
    font-family: 'Muli Bold', sans-serif;
    color: #FFF;
    font-size: 14px;
    padding: 0 8px;
}

.legal-external-links:hover {
    color: #2C2B7C;
}

.legal-info-divider {
    position: absolute;
    right: 0;
    top: 16%;
    width: 2px;
    height: 12px;
    background-color: #2c2b7c;
}
