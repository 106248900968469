.stepper-card {
    padding: 5px 15px;
    width: 700px;
    height: 450px;
    border-radius: 18px;
    background-color: #FFFFFF;
}

.stepper-main-body {
    width: 100%;
    height: calc(100% - 50px);
}

.stepper-title {
    font-family: 'Poppins Semi Bold', sans-serif;
    margin-top: 10px;
    font-size: 24px;
    color: #2C2B7C;
}

.stepper-info {
    font-size: 14px;
    color: #252A2D;
    text-align: left;
}

.stepper-footer {
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.stepper-indicator-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
}

.stepper-input-green {
    font-family: 'Poppins Light', sans-serif;
    color: #2c2b7c !important;
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 100%;
    font-size: 14px;
}

.stepper-page-indicator {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 3px solid #CFCFCF;
}

.stepper-page-indicator:not(:first-child) {
    margin-left: 5px;
}

.active-stepper-indicator {
    background-color: #A3CF62;
    border-color: #A3CF62;
}

.stepper-btn-div {
    font-size: 14px;
    color: #2C2B7C;
    font-weight: bold;
    cursor: pointer;
}

.success-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #2C2B7C;
}

.success-info {
    font-size: 15px;
    color: #252A2D;
    text-align: left;
    letter-spacing: 0px;
}

#add-payment-btn {
    text-decoration: none;
    font-size: 16px;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 70%;
    height: 38px;
    font-weight: bold;
    margin-top: 25px;
}

#signup-back-btn {
    color: #2C2B7C;
    font-size: 14px;
    margin-top: 15px;
}

#preview-btn {
    margin-top: 20px;
    color: #2C2B7C;
    text-decoration: underline;
    font-size: 12px;
}

.disabled-page-btns {
    opacity: 0.5;
}

.reactSelect{
    z-index: inherit;
}
