.position-modal {
    height: 870px !important;
    width: 950px !important;
    max-width: 950px;
}

.position-modal-title {
    height: 50px;
    font-size: 18px;
    font-family: 'Poppins Medium', sans-serif;
    text-align: left;
    letter-spacing: 0;
}

.position-modal-body {
    height: calc(100% - 50px);
}

.position-section-header {
    font-family: 'Poppins Medium', sans-serif;
    font-size: 12px;
    color: black;
    border-bottom: 2px solid #A3CF62;
}

.position-input-green {
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 80%;
}

#position-select {
    width: 80%;
}


#send-application-txt {
    font-size: 12px;
    color: #2C2B7C;
    line-height: 2;
}

.position-custom-btn {
    margin-top: 15px;
    background-color: #FFFFFF;
    color: #2C2B7C;
    font-size: 12px;
    border: 2px solid #A3CF62;
    border-radius: 30px;
    min-height: 35px;
    width: 80%;
}

.create-question-btn {
    margin-top: 15px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 40px;
    width: 80%;
}

.availability-custom-btn {
    margin-top: 10px;
    background-color: #FFFFFF;
    color: #2C2B7C;
    font-size: 12px;
    border: 2px solid #A3CF62;
    border-radius: 30px;
    min-height: 35px;
    width: 165px;
}

.availability-custom-btn:focus, .position-custom-btn:focus {
    outline: none;
}

.availability-day-name {
    font-size: 14px;
    color: #2C2B7C;
    letter-spacing: 1px;
}

.time-input-green {
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 140px;
    height: 38px;
}

.information-div {
    height: 85%;
    max-height: 85%;
}

.button-div {
    border-top: 1px solid #A3CF62;
    background-color: white;
    margin-top: 10px;
    height: 15%;
    width: 100%;
}

#create-opening-btn {
    margin-top: 15px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 40px;
    width: 350px;
    font-weight: bold;
}

.active-modal-btn {
    background-color: #A3CF62;
    color: white;
    font-weight: bold;
}

#position-modal-close {
    color: #2c2b7c;
    font-size: 20px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

#information-div {
    height: 85%;
}

/*Accordion*/

.accordion-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-family: 'Muli Light', sans-serif;
    color: #2c2b7c;
    height: 40px;
    width: 100%;
    text-transform: uppercase;
    border-bottom: 1px solid #A3CF62;
}

.selected-time-wrapper {
    height: 40px;
}

.availability-time-label {
    width: 100px;
    text-align: center;
    font-family: 'Poppins Light', sans-serif;
    color: #2c2b7c;
}

.availability-delete-btn {
    color: #EE6C4D;
    font-size: 18px;
    margin-left: 15px;
}

#availability-save-btn {
    font-family: 'Poppins Bold', sans-serif;
    background-color: #2C2B7C;
    font-size: 12px;
    border-radius: 30px;
    width: 50px;
}

#availability-add-btn {
    font-family: 'Poppins Light', sans-serif;
    text-transform: lowercase;
    font-size: 12px;
    color: #2c2b7c;
}

#time-vary-lbl {
    font-family: 'Poppins Light', sans-serif;
    text-transform: lowercase;
    font-size: 12px;
    color: #2c2b7c;
}

#availability-add-btn:hover {
    outline: none;
    background-color: transparent;
}

#availability-add-btn:focus {
    box-shadow: none !important;
    background-color: transparent;
}
