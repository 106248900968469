.location-card {
    width: 100%;
    height: 150px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 10px;
}

.location-card-no-effect {
    width: 100%;
    height: 150px;
    border-radius: 20px;
    margin-top: 10px;
}

.location-card:hover {
    border: 3px solid #2C2B7C;
}

.no-effect {
    border: 10px solid;
}

.location-card:hover .manage-location-ellipsis {
    background-color: #2C2B7C !important;
}

.location-dropdown-menu {
    width: 210px;
    height: auto;
}

.loc-dropdown-item {
    height: 35px;
    width: 100% !important;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: black;
    font-size: 14px;
}

.address-title {
    font-family: "Muli Bold", sans-serif;
    font-size: 14px;
}

.address-value {
    font-family: "Muli Regular", sans-serif;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 250px;
    display: block;
    overflow: hidden;
}

.manage-location-ellipsis {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border: none;
    background: transparent;
}

.manage-location-ellipsis:after {
    content: none;
}

@media only screen and (max-width: 991px) {
    .location-card {
        height: auto;
    }
}
