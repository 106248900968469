#job-listing-table {
    height: 100%;
    width: 100%;
}

.job-listing-table-head {
    font-family: "Muli Bold", sans-serif;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #F3E7D0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-transform: uppercase;
    color: #2C2B7C;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #A3CF62;
}

.job-listing-table-body {
    height: calc(100% - 50px);
    width: 100%;
    padding: 5px;
    cursor: pointer;
    overflow-y: auto;
}

.job-listing-table-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.job-listing-table-body::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.job-listing-table-body::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}

.pt-td {
    padding: 5px;
    width: 100%;
    height: 55px;
    margin-top: 15px;
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
}

.position-name {
    font-family: 'Muli Bold', sans-serif;
    font-size: 14px;
    color: #2C2B7C;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.job-location {
    font-family: 'Muli Bold', sans-serif;
    color: #2C2B7C;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.position-id {
    font-family: 'Muli Regular', sans-serif;
    color: #6A6D6D;
    font-size: 12px;
    text-transform: lowercase;
    margin-left: 10px;
}

.job-listing-table-tr:hover {
    background-color: #2C2B7C;
}

.jl-location-ellipsis {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border: none;
    background: transparent;
}

.jl-location-ellipsis:after {
    content: none;
}

.jl-dropdown-menu {
    width: 210px;
    height: auto;
}

.jl-dropdown-item {
    height: 35px;
    width: 100% !important;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: black;
    font-size: 14px;
}

.jl-stats {
    font-family: 'Muli Regular', sans-serif;
    font-size: 14px;
    color: black;
}

.jl-underlined {
    text-decoration: underline;
}

.jl-underlined:hover {
    color: rgb(238, 108, 77) !important;
    font-weight: bold;
}

.job-listing-table-tr:hover .position-name, .job-listing-table-tr:hover .position-id, .job-listing-table-tr:hover .jl-ellipsis-icon, .job-listing-table-tr:hover .job-location, .job-listing-table-tr:hover .jl-stats {
    color: #FFFFFF;
}

.jl-location-ellipsis:hover {
    background-color: #FFFFFF !important;
    box-shadow: 0 0 0 0 transparent;
}

.jl-location-ellipsis:hover path {
    fill: #2c2b7c;
}

.jl-ellipsis-icon {
    color: #2C2B7C;
}
