.delete-confirmation-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 100%;
    background-color: #F3E7D0;
}

.delete-conf-header{
    font-family: "Poppins Medium", sans-serif;
    text-align: justify;
    color: #2C2B7C;
    width: 80%;
    font-size: 28px;
    line-height: 1;
}

.delete-conf-info {
    font-family: "Poppins Light", sans-serif;
    text-align: justify;
    color: #2C2B7C;
    width: 80%;
    font-size: 20px;
    line-height: 1;
    margin: 50px 0;
}
