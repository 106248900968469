
.locationRow {
    height: 100px;
}

.location-table-row {
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.location-table-row::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.location-table-row::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.location-table-row::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}

.location-header {
    font-size: 16px;
    font-family: 'Muli Bold', sans-serif;
    text-transform: uppercase;
    color: #2C2B7C;
    text-align: left;
    line-height: 1;
    margin: 0 5px;
}

.search-name-input {
    display: flex;
    flex-flow: row nowrap;
    border-radius: 19px;
    border: 2px solid #A3CF62;
    width: 285px !important;
    height: 40px;
    cursor: pointer;
}

.icon-div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 100%;
}

.input-div {
    width: 85%;
    height: 100%;
}

.location-search-input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
}

#addLocationBtn {
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 100%;
}

#setCoordinatesOnMap {
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 100%;
}

#addNewLocationBtn {
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 150px;
}

#locationInfo {
}

.starCount {
    color: #2C2B7C;
    font-size: 19px;
}

.countData {
    font-size: 18px;
    width: 15%;
}

.positionName {
    color: #2C2B7C;
    font-weight: bold;
}

.locationTable {
    padding-top: 2%;
    padding-bottom: 2%;
}

.tableHead {
    font-size: 20px;
}

.tableBody {
    font-size: 18px;
}

#appLink {
    text-decoration: none;
    color: #2C2B7C;
    font-size: 12px;
    line-height: 15px;
}

.position {
    width: 35%;
}

#cardText {
    font-size: 12px;
    font-weight: bold;
}

#locationTitle {
    font-size: 18px;
}

#info {
    font-size: 14px;
    font-weight: lighter;
}

.add-location-modal {
    width: 365px;
}

.modal-content {
    height: 410px !important;
    width: 360px !important;
    border-radius: 28px;
    padding: 10px;
}

.modal-body-container {
    width: 100%;
    height: 100%;
}

.location-modal-title {
    font-family: 'Poppins Medium', sans-serif;
    padding: 8px 0;
    position: relative;
    font-size: 18px;
    border-bottom: 1px solid #A3CF62;
}

.modal-input-green {
    font-family: 'Poppins Light', sans-serif;
    font-size: 13px;
    color: #2c2b7c !important;
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 100%;
    height: 40px;
}

#location-select {
    color: #2c2b7c;
    font-family: 'Poppins Light', sans-serif;
    font-size: 13px;
}

#location-modal-close {
    color: #2c2b7c;
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    display: flex;
}

.no-data {
    margin: auto 0;
    display: flex;
    justify-content: center;
    color: #2C2B7C;
    font-weight: 800;
    font-size: 40px;
}

.leaflet-container {
    height: 660px;
    width: 100%;
}
