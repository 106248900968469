#applicant-table {
    height: 100%;
    width: 100%;
}

.application-table-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-transform: uppercase;
    color: #2C2B7C;
    font-weight: bold;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #A3CF62;
}

.no-data {
    display: flex;
    justify-content: center;
    color: #2C2B7C;
    font-weight: 800;
    font-size: 40;
}

.application-table-body {
    height: calc(100% - 50px);
    width: 100%;
    cursor: pointer;
    overflow-y: auto;
}

.application-table-body::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.application-table-body::-webkit-scrollbar-track {
    background: #888;
    border-radius: 10px;
}

.application-table-body::-webkit-scrollbar-thumb {
    background: #2C2B7C;
    border-radius: 10px;
}

.pt-td {
    padding: 5px;
    width: 100%;
    height: 55px;
    margin-top: 15px;
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
}

.applicant-name {
    font-family: 'Muli Bold', sans-serif;
    font-size: 14px;
    color: #2C2B7C;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 15px;
}

.job-listing {
    font-family: 'Muli Regular', sans-serif;
    color: #2C2B7C;
    text-transform: uppercase;
}

.applicant-img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.application-date {
    font-family: 'Muli Regular', sans-serif;
    color: #2C2B7C;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 10px;
}

.applicant-table-tr:hover {
    background-color: #2C2B7C;
}

.applicant-table-tr:hover .applicant-name, .applicant-table-tr:hover .application-date, .applicant-table-tr:hover .message-icon, .applicant-table-tr:hover .job-listing, .applicant-table-tr:hover .applicant-status {
    color: #FFFFFF;
}

.message-icon {
    color: #2C2B7C;
}


.spinner-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    display: flex;
}

.applicant-status {
    font-family: 'Muli Regular', sans-serif;
    color: #2C2B7C;
    font-size: 14px;
}

.applicant-table-ellipsis {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border: none;
    background: transparent;
}

.applicant-table-ellipsis:after {
    content: none;
}

.applicant-table-ellipsis:hover {
    background: white;
}

.applicant-table-tr:hover path {
    fill: #FFF;
}

.applicant-table-ellipsis:hover path {
    fill: #2c2b7c;
}

.applicant-dropdown-item {
    font-family: "Poppins Regular", sans-serif;
    font-size: 14px;
    color: #2C2B7C;
}

.applicant-dropdown-item path, .applicant-dropdown-item:hover path {
    fill: #2c2b7c;
}
