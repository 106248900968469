.banner-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-family: 'Muli Bold', sans-serif;
    height: 25px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    color: #2c2b7c;
    border-bottom: 2px solid #A3CF62;
}

.banner-checkbox > .form-check-input {
    width: 18px;
    height: 18px;
}

.banner-checkbox .form-check-input[type=checkbox] {
    background-color: #2c2b7c;
}

.custom-image-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 90px;
    border: 3px solid #A3CF62;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 2.5rem;
    color: #6A6D6D;
}

.custom-image-container:hover {
    color: #2c2b7c;
}

#customImageUploadBtn {
    font-family: 'Poppins Bold', sans-serif;
    margin-left: 20px;
    background-color: #2C2B7C;
    border-radius: 22px;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 15px;
    width: 90px;
    height: 35px;
}

.jipe-image-container {
    width: 340px;
    height: 90px;
    border-radius: 10px;
    background: url("../../../../../assets/banner.png") no-repeat center;
    background-size: cover;
}
