.subscription-modal .modal-content {
    padding: 0;
    border-radius: 19px;
    height: 300px !important;
}

.subscription-modal {
    width: 370px;
}

.subscription-header {
    color: #2c2b7c;
    text-align: center;
    letter-spacing: 0px;
    font-family: 'Poppins Bold', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

#cancel-subs-btn {
    background-color: #2C2B7C;
    color: white;
    border-radius: 33px;
    width: 150px;
    font-weight: bold;
}

#confirm-subs-btn {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    background-color: white;
    border: 1px solid #2c2b7c;
    border-radius: 33px;
    width: 150px;
    font-weight: bold;
}

.confirmation-header {
    padding: 0 10px;
    font-size: 14px;
    letter-spacing: 0px;
    text-align: center;
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
}

.confirmation-content {
    font-size: 14px;
    letter-spacing: 0px;
    text-align: center;
    font-family: 'Poppins Light', sans-serif;
    color: #2C2B7C;
}
