.billing-confirm-modal .modal-content {
    width: 350px;
    height: 175px !important;
}

#confirm-final-btn {
    text-transform: uppercase;
    font-size: 16px;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 45%;
    height: 38px;
}

.active-info {
    font-family: 'Poppins Bold', sans-serif;
    margin-top: 5px;
    font-size: 16px;
    color: #2c2b7c;
    text-align: center;
}

#confirmation-cancel-btn {
    font-family: 'Poppins Bold', sans-serif;
    border: 1px solid #2c2b7c !important;
    border-radius: 33px;
    width: 45%;
    background-color: transparent;
    color: #2c2b7c;
}

#confirmation-cancel-btn:hover {
    background-color: #EE6C4D;
    border-color: #EE6C4D !important;
    color: #FFFFFF;
}
