.logo-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.logo-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    border: 2px solid #2C2B7C;
    color: #6A6D6D;
    font-size: 32px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#logoUploadBtn {
    font-family: 'Poppins Bold', sans-serif;
    margin-top: 20px;
    background-color: #2C2B7C;
    border-radius: 22px;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 15px;
}

.logo-container:hover {
    color: #2c2b7c;
}
