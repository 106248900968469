.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
}

.sidebar__header {
    display: flex;
    padding: 20px;
    background-color: white;
    color: #7373A8;
    font-weight: bolder;
}

.sidebar__chatinfo {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
}

.sidebar__chatMessage {
    position: relative;
    border: 1px solid lightgray;
    display: flex;
    padding: 5px 10px;
    margin: 10px 5px;
    width: 90%;
    min-height: 60px;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    transition: transform 50ms ease-in;
}

.sidebar-unread-badge {
    position: absolute;
    top: -3%;
    left: 0;
    height: 14px;
    width: 14px;
    background: #EE6C4D;
    border-radius: 50%;
}

.sidebar__chatMessage:hover {
    transform: scale(1.02);
}

.sidebar__avatar {
    background: transparent 0 0 no-repeat padding-box;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-self: center;
}

.sidebarChatMessage__info {
    width: calc(100% - 40px);
    height: 100%;
    padding: 0 5px;
    align-self: center;
}

.recipient-info {
    height: 50%;
}

.recipient-message {
    display: flex;
    height: 50%;
    width: 100%;
}

.recipient-location {
    letter-spacing: 0;
    margin-left: 5px;
    color: #252A2D;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font: normal normal normal 12px/18px 'Poppins Regular', sans-serif;
}

.recipient-message-time {
    letter-spacing: 0;
    color: #252A2D;
    flex: 1 0 100%;
    height: 100%;
    opacity: 0.5;
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
    /*white-space: nowrap;*/
    font: normal normal normal 12px/18px 'Poppins Regular', sans-serif;
}

.recipient-message-text {
    letter-spacing: 0;
    color: #252A2D;
    font: normal normal normal 12px/18px 'Poppins Regular', sans-serif;
    flex: 1 0 80%;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.recipient-name {
    color: #252A2D;
    font: normal normal bold 12px/18px 'Poppins Bold', sans-serif;
}

.sidebarChatMessage__info > h2 {
    font-size: small;
    margin-left: 10px;
}

.sidebarChatMessage__info > p {
    font-size: small;
    margin-left: 10px;
    color: gray;

}

.sidebar__headerRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10vw;
}

.sidebar__chats {
    flex: 1;
    background-color: white;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.sidebar__chats::-webkit-scrollbar {
    display: none;
}

#sidebar-message-dropdown {
    color: #2C2B7C;
    letter-spacing: 0px;
    font: normal normal bold 12px/15px 'Muli Light', sans-serif;
}

.sidebar-message-item {
    letter-spacing: 0px;
    color: #2C2B7C;
    text-transform: uppercase;
    font: normal normal bold 12px/15px 'Muli Light', sans-serif;
}

.sidebar-no-messages-info-txt {
    display: flex;
    align-items: center;
    font-family: 'Muli Regular', sans-serif;
    font-weight: 400;
    font-size: 20px;
    height: 100%;
}


