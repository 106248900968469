
.profileCol {
    width: 100%;
    font-size: 20px;
    font-family: 'Muli Bold', sans-serif;
    font-weight: bold;
    border-bottom: 1px solid #A3CF62;
    padding: 10px 0;
    margin-top: 30px;
}

#profileCard {
    margin-top: 20px;
    padding: 15px;
    border-radius: 18px;
    background-color: #FFFFFF;
}

.profile-card-header {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 25px;
}

#profileBtn {
    font-family: 'Poppins Bold', sans-serif;
    background-color: #2C2B7C;
    color: white;
    border-radius: 33px;
    width: 165px;
}

#saveBtn {
    font-family: 'Poppins Bold', sans-serif;
    background-color: #2C2B7C;
    color: white;
    border-radius: 33px;
    width: 100px;
}

.stickFooter {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #F3E7D0;
}

.profileContainer {
    background-color: #F3E7D0;
    width: 85%;
    height: 100%;
}

#profile-logo-container {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    margin-top: 10px;
    border: 2px solid #2C2B7C;
    border-radius: 50%;
    background-size: cover;
}

#profile-logo-container::after {
    content: "";
    background: white;
    opacity: 0.9;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: -1;
}

#profile-logo-btn {
    background-color: #2C2B7C;
    border-radius: 22px;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    padding: 2px 10px;
    margin-left: 20px;
}

#profile-banner-image-container {
    width: 160px;
    height: 65px;
    border: 3px solid #A3CF62;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 1.5rem;
    color: #6A6D6D;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

#profile-banner-image-container:hover {
    color: #2c2b7c;
}

.profile-banner-title {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 12px;
    color: black;
}

#jipe-banner-container {
    width: 160px;
    height: 65px;
    border-radius: 10px;
    background: url("../../../../assets/banner.png") no-repeat center;
    background-size: cover;
}

.profile-description-input {
    border: 2px solid #A3CF62;
    color: #2C2B7C !important;
    border-radius: 24px;
    width: 100%;
    height: 115px !important;
}

#desc-header {
    font-size: 14px;
    font-family: 'Poppins Bold', sans-serif;
    color: black;
    font-weight: bold;
    text-align: left;
}

.profile-photo-video-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 150px;
    margin-right: 20px;
    border-radius: 16px;
    background-color: #6A6D6D;
    background-size: cover;
    margin-top: 10px;
}

.industry-checkbox {
    margin-top: 0.30rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #2c2b7c;
    font-family: 'Muli Bold', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}

.industry-checkbox .form-check-input {
    width: 20px;
    height: 20px;
}

.industry-checkbox .form-check-label {
    margin-left: 15px;
    margin-top: 7px;
}

#stickyBtn {
    width: auto;
    position: sticky;
    top: 400px;
}

.ep-social-input {
    font-family: 'Muli Regular', sans-serif;
    color: #2c2b7c !important;
    width: 340px;
    height: 38px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #A3CF62;
    border-radius: 24px;
}

#profile-create-qn-btn {
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 45px !important;
    width: 250px !important;
}

#no-qn-lbl {
    font-family: 'Muli Light', sans-serif;
    color: #2c2b7c;
    font-size: 20px;
    margin: auto;
}

.fix-margin {
    margin-top: 0px !important
}

.profile-cancel-btn {
    font-family: 'Poppins Bold', sans-serif;
    border: 1px solid #2c2b7c !important;
    border-radius: 33px;
    width: 150px;
    background-color: transparent;
    color: #2c2b7c;
    margin-top: 15px;
    margin-left: 15px;
}

.btn-outline > .disabled {
    background-color: transparent !important;
}

.profile-cancel-btn:hover {
    background-color: #EE6C4D;
    color: #FFFFFF;
    border-color: #EE6C4D !important;
}
