.navbar-container {
    width: 100%;
    height: 70px;
}

.body-container {
    width: 100%;
    overflow-y: auto;
}

.footer-container {
    width: 100%;
    height: 110px;
}

