.card-info-header {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.card-input-green {
    font-family: 'Poppins Light', sans-serif;
    font-size: 12px;
    border: 2px solid #A3CF62;
    border-radius: 19px;
    width: 100%;
    height: 38px;
}

.custom-stripe-input-wrapper {
    border: 2px solid #A3CF62;
    border-radius: 19px;
    width: 100%;
    height: 38px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 10px;
}

.StripeElement {
    width: 100% !important;
}

#card-location-select {
    font-family: 'Poppins Light', sans-serif;
    color: #2c2b7c;
    font-size: 12px;
    max-height: 35px;
}
