.breadcrumb-container {
    height: 50px;
    width: 100%;
    padding: 0 7.8%;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 6px #00000029;
    position: sticky;
    top: 0;
    z-index: 1;
}

.position-breadcrumb {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #2C2B7C;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.jipe-breadcrumb-arrow {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center
}

#position-header-card {
    height: 60px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    margin: 15px 0 0;
}

#position-header-name {
    font-size: 18px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

#position-header-id {
    font-size: 12px;
    color: #6A6D6D;
    text-transform: lowercase;
    margin-left: 10px;
}

#position-table-container {

}

#position-card-wrapper {
    width: 100%;
    height: calc(100% - 70px);
    margin: 10px 0 0 0;
    padding-right: 0;
    padding-left: 0;
}

#position-table-wrapper {
    width: 100%;
    height: calc(100% - 110px);
    margin: 20px 0 0 0;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    display: flex;
}

#position-card-container > #applicationCard:nth-child(n + 2) {
    margin-left: 20px;
}

#position-card-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-x: scroll;
}

#position-table-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
}

#position-card-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
    background-color: transparent;
}

#position-card-container::-webkit-scrollbar {
    width: 2px !important;
    background-color: #F5F5F5;
}

#position-card-container::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}

.position-dropdown-menu {
    width: 210px;
    height: 95px;
}

.position-header-ellipsis {
    margin-right: 10px;
}

.position-header-ellipsis:focus {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.position-header-ellipsis:after {
    content: none;
}

.show > .btn-primary.dropdown-toggle {
    background: transparent;
    border: none;
    box-shadow: none !important;
}

.position-header-ellipsis, .position-header-ellipsis:hover {
    background: transparent;
    border: none;
}

.position-dropdown-item {
    height: 35px;
    width: 100% !important;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: black;
    font-size: 14px;
}

.view-toggle-btns {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;

}

.toggle-pill {
    background-color: #a6acb3;
    width: 100%;
    height: 30px;
    border-radius: 20px;
    overflow: hidden;
}

.toggle-active {
    background-color: #2c2b7c;
    color: #FFFFFF !important;
}

.toggle-btn-icons {
    color: inherit;
    font-size: 15px;
}

.jipe-blue {
    color: #2c2b7c;
    cursor: pointer;
}

.applicant-toggle {
    background-color: #a6acb3;
    color: #FFFFFF;
    cursor: pointer;
}

.applicant-toggle-active {
    background-color: #A3CF62;
    color: #2c2b7c !important;
    font-weight: bold;
}
