.fp-title {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.loginRow {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #F3E7D0;
    height: 100%;
    width: 50%;
}

.forgot-password-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 425px;
    border-radius: 30px;
    z-index: 10;
}

.mainLogo {
    width: 25%;
}

.backgroundCol {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 30%;
    width: 100%;
    background-image: url("../../../assets/Jipe_illustration.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

#resetPassBtn {
    font-family: 'Poppins Bold', sans-serif;
    background-color: #2C2B7C;
    width: 150px;
    font-size: 16px;
    border-radius: 22px;
    font-weight: bold;
    text-transform: uppercase;
}

#back-btn {
    font-family: 'Poppins Regular', sans-serif;
    position: absolute;
    bottom: 5%;
    color: #2C2B7C;
    font-size: 16px;
}

.reset-email-label {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 16px;
    width: 60%;
    margin: 0 auto;
    letter-spacing: 0;
}

.fp-input-green {
    font-family: 'Muli Light', sans-serif;
    font-size: 14px;
    border-color: #A3CF62 !important;
    color: #2C2B7C !important;
    border-radius: 19px;
    border-width: 2px;
    width: 70%;
    height: 38px;
}
