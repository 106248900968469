.deletion-jipe-logo {
    height: 100px;
    width: 250px;
}

.deletion-content-wrapper {
    min-height: calc(100% - 110px);
    width: 100%;
    display: flex;
    justify-content: center;
}

.deletion-text-container {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 110px;
    text-align: justify;
}

.deletion-header-txt {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 32px;
    line-height: 1;
    font-weight: 800;
    text-transform: uppercase;
}

.deletion-info-header-txt {
    font-family: 'Muli Bold', sans-serif;
    font-weight: 60;
    font-size: 20px;
    margin-top: 10px;
}

.deletion-info-txt {
    font-family: 'Muli Regular', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
}

.deletion-note-txt {
    font-family: 'Muli Light', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-top: 55px;
}

@media (max-width: 1080px) {
    .deletion-header-txt {
        font-size: 30px;
    }

    .deletion-info-header-txt {
        font-size: 16px;
    }

    .deletion-info-txt {
        font-size: 14px;
    }

    .deletion-note-txt {
        font-size: 12px;
    }
}