.stepper-plan-header {
    letter-spacing: 0px;
    font-family: 'Poppins Bold', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
}

.pricing-stepper-offer-tag {
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Poppins Semi Bold', sans-serif;
    color: #A3CF62;
    text-align: center;
}

.pricing-stepper-info {
    font-size: 14px;
    font-family: 'Poppins Regular', sans-serif;
    color: #2C2B7C;
    text-align: center;
}

.plan-price {
    font-family: 'Poppins Bold', sans-serif;
    text-transform: uppercase;
    text-decoration: line-through;
    color: #EE6C4D;
    font-size: 40px;
    line-height: 1;
}

.stepper-subscription-list-lbl {
    font-family: 'Poppins Light', sans-serif;
    color: #2c2b7c;
    font-size: 19px;
}

.stepper-plan-per-list-lbl {
    font-family: 'Poppins Medium', sans-serif;
    text-transform: uppercase;
    color: #EE6C4D;
    font-size: 15px;
}

.stepper-trial-lbl {
    font-family: 'Poppins Bold', sans-serif;
    color: #A3CF62;
    font-size: 15px;
}


.stepper-plan-sub-header {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0;
    text-align: center;
    font-family: 'Poppins Medium', sans-serif;
}

.stepper-plan-card {
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFFFFF;
    color: #2C2B7C;
    width: calc(100% / 4);
}

.selected-plan {
    color: #F3E7D0 !important;
    background-color: #2c2b7c !important;
    box-shadow: 0px 0px 3px 2px #7fb7be;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.spinner {
    display: flex;
}

.no-pricing-plans {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: #2c2b7c;
    margin-top: 10%;
}

.buy-plan-btn {
    width: 90%;
    height: 40px;
    border: 1px solid #2c2b7c;
    text-transform: uppercase;
    background-color: #FFFFFF;
    border-radius: 28px;
    color: #2c2b7c;
    font-family: 'Poppins Bold', sans-serif;
}

.buy-plan-btn:focus {
    outline: none;
}

.stepper-plan-card:last-child {
    border: none !important;
}
