.availability-custom-btn {
    margin-top: 10px;
    background-color: #FFFFFF;
    color: #2C2B7C;
    font-size: 12px;
    border: 2px solid #A3CF62;
    border-radius: 30px;
    min-height: 35px;
    width: 150px;
}

.active-modal-btn {
    background-color: #A3CF62;
    color: white;
    font-weight: bold;
}

.time-picker-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: center;
    position: relative;
    border: 2px solid #A3CF62;
    border-radius: 28px;
    width: 140px;
    height: 38px;
    overflow: hidden;
    padding: 0 5px;
}

.react-select-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: center;
    position: relative;
    width: 140px;
    height: 38px;
    padding: 0 5px;
}

.time-input {
    width: 100%;
    height: 100%;
    line-height: 1 !important;
}

.MuiInput-underline:before {
    transition: none;
    border-bottom: 0 !important;
    pointer-events: none;
}

.Mui-focused {
    transition: none !important;
}