.application-overflow-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}


.application-category-header {
    font-family: 'Muli Bold', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    font-size: 16px;
    font-weight: bold;
    color: #2c2b7c;
    border-bottom: 2px solid #A3CF62;
}

.application-category-checkbox > .form-check-input {
    width: 18px;
    height: 18px;
    background-color: #2c2b7c;
}

.application-category-desc {
    font-family: 'Poppins Regular', sans-serif;
    padding: 5px 0;
    font-size: 12px;
    width: 521px;
    text-align: left;
    color: #707070;
}

.job-overflow-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.job-overflow-wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.job-overflow-wrapper::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}
