.deactivation-modal {
    max-width: 500px;
}

.modal-body-container {
    width: 100%;
    height: 100%;
}

.deactivation-modal > .modal-content {
    height: 410px !important;
    width: 100% !important;
    border-radius: 28px;
    padding: 10px;
}

.deactivation-question-header {
    font-family: 'Poppins Semi Bold', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #2c2b7c;
    height: 10%;
}

#continue-deactivate-btn {
    font-family: 'Poppins Bold', sans-serif;
    color: #FFFFFF;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 150px;
    height: 10%;
    margin-bottom: 25px;
}

.deactivation-reasons {
    text-transform: uppercase;
    color: #2C2B7C;
    font-family: 'Poppins Medium', sans-serif;
    font-size: 14px;
    padding: 5px;
}
