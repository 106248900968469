.social-icon {
    width: 30px;
    height: 30px;
    background: #2C2B7C 0% 0% no-repeat padding-box;
    border-radius: 50%;
}

.social-input {
    width: 340px;
    height: 38px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #A3CF62;
    border-radius: 24px;
}
