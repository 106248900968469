#basic-nav-dropdown:after {
    content: none
}

.navImage {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
}

#navItem {
    color: #2C2B7C;
    display: block;
    padding: 4px 20px;
}

#navItem:hover {
    background-color: #2c2b7c;
    color: #FFFFFF;
}

#navLink:hover {
    border-bottom: solid 4px #A3CF62;
}

#mainNav {
    background-color: #2C2B7C;
    padding: 0 10px;
    height: 100%;
}

.active {
    text-decoration: none;
    color: white;
    border-bottom: solid 4px #A3CF62;
}

#basic-nav-dropdown {
    color: white;
    margin-right: 50px;
}

#navLink {
    color: white;
    font: normal normal 0.875rem/1.125rem 'Muli Light', sans-serif;
    text-align: center;
    width: 210px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.navbar-link-items {
    height: 100%;
}

.navbar-link-item {
    margin-right: auto;
    height: 100%;
}

#basic-navbar-nav .dropdown-menu {
    right: 5px !important;
    left: auto !important;
    width: 215px;
}

#dropdown-item-button {
    background-color: transparent;
    width: 70px;
    height: 70px;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: right;
}

#dropdown-item-button:focus {
    box-shadow: none;
}

#dropdown-item-button::after {
    content: none;
}

#navbar-businessname {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    color: #FFFFFF;
}

#navbar-username {
    font-family: 'Muli Regular', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 300px;
}

.navbar-unread-badge {
    position: absolute;
    top: 25%;
    left: 20%;
    height: 12px;
    width: 12px;
    background: #EE6C4D;
    border-radius: 50%;
}

@media screen and (max-width: 1280px) {
    #navLink {
        width: 160px;
    }
}

@media screen and (max-width: 992px) {
    .show {
        margin-top: 4px;
        width: 100%;
        z-index: 999;
        height: auto;
        background-color: #2C2B7C;
    }

    .navbar-link-item {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    #navLink {
        width: 100%;
    }

    .dropdown {
        text-align: center;
    }

    #navItem {
        color: #ffffff;
    }

    #navItem:hover {
        color: #2C2B7C;
    }
}
