.oops-main-wrapper {
    background-color: #F3E7D0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.oops-logo {
    height: 200px;
    width: 200px;
    margin-top: 150px;
}

.download-text-container {
    text-align: center;
    margin-top: 50px;
}

.woops-txt {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 42px;
    line-height: 1;
    font-weight: 800;
    text-transform: uppercase;
}

.woops-info {
    font-family: 'Muli Regular', sans-serif;
    font-size: 18px;
    color: #A3CF62;
    margin-top: 30px;
}

.download-btn-container {
    /*background-color: #2c2b7c;*/
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 50px;
    width: 400px;
}

.download-btn {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: black;
    width: 180px;
    height: 60px;
    border-radius: 5px;
    color: white;
}

.apple-logo {
    width: 40px;
    height: 40px;
}

.play-logo {
    width: 40px;
    height: 40px;
}

.download-text {
    font-family: 'Poppins Medium', sans-serif;
}

.download-btn svg {
    fill: white !important;
}
.download-btn:hover {
    background-color: #FFFFFF;
    color: black !important;
}

.download-btn:hover g {
    fill: black;
}

