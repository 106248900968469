.HiringMangerSignUpContainer {
    background-color: #F3E7D0;
    width: 85%;
}

.welcome_text {
    font: normal normal bold 20px/30px 'Poppins Bold', sans-serif;
    color: #2c2b7c;
    margin-top: 30px;
    letter-spacing: 0;
    text-transform: uppercase;
}

.ms-user-info {
    margin-top: 5px;
    font: normal normal normal 14px/18px 'Muli Regular', sans-serif;
    color: #2c2b7c;
    letter-spacing: 0;
    text-transform: uppercase;
}

.information {
    margin-top: 15px;
    font-family: 'Muli Regular', sans-serif;
    color: #2C2B7C;
    font-size: 16px;
}

.last_name {
    margin-bottom: 40px;
}

.upload_picture {
    color: #2C2B7C;
    text-decoration: underline;
}


.jipe-input-green {
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 270px;
}

.signup-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 50%;
    border-radius: 10%;
    z-index: 10;
    padding-top: 5%;
}

#saveBtn {
    background-color: #2C2B7C;
    color: white;
    border-radius: 33px;
    width: 150px;
    font-weight: bold;
    margin-top: 15px;
}

.MuiAvatar-colorDefault {
    color: #2C2B7C !important;
    background-color: #ffffff !important;
    border: 2px solid #A3CF62;
}

.terms-and-policy-ms {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 50px;
}
