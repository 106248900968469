.ca-input-green {
    font-family: 'Muli Light', sans-serif;
    color: #2c2b7c !important;
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 100% !important;
}

.create-account-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 425px;
    border-radius: 10%;
    z-index: 10;
    padding-top: 5%;
}

.create-acc-title {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 26px;
    color: #2c2b7c;
    font-weight: 700;
    text-transform: uppercase;
}

#ca-btn {
    font-size: 16px;
    font-family: 'Poppins Bold', sans-serif;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #2c2b7c;
    border-radius: 28px;
    width: 150px;
    margin-top: 15px;
    text-transform: uppercase;
}

.terms-and-policy {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.signup-terms-link {
    display: flex;
    flex-flow: row nowrap;
    height: 50%;
    width: 150px;
    font-family: 'Poppins Light', sans-serif;
    font-size: 14px;
}

.terms-checkbox {
    height: 8px;
    width: 8px;
}

@media only screen and (max-width: 1200px) {
    .logoCol {
        display: none;
    }

    .loginRow {
        width: 100%;
    }
}
