.main-container {
    height: 100%;
    width: 100%;
    text-align: center;
}

.backgroundCol {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 30%;
    width: 100%;
    background-image: url("../../../assets/Jipe_illustration.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.loginRow {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #F3E7D0;
    height: 100%;
    width: 50%;
}

.containerRow {
    display: flex;
    flex-flow: row wrap;
    background-color: rebeccapurple;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.login-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 425px;
    border-radius: 30px;
    z-index: 10;
}

.login-title {
    color: #2c2b7c;
    text-transform: uppercase;
    font-size: 26px;
    font-family: 'Poppins Bold', sans-serif;
}

.jipe-input-blue {
    font-family: 'Muli Light', sans-serif;
    border-color: #A3CF62 !important;
    color: #2C2B7C !important;
    border-radius: 19px;
    border-width: 2px;
    width: 100%;
}

#signUpBtn {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    text-decoration: none;
    font-size: 14px;
}

#formText {
    font-family: 'Muli Regular', sans-serif;
    font-size: 12px;
    color: #252A2D;
}

#loginBtn {
    font-family: 'Poppins Bold', sans-serif;
    background-color: #2C2B7C;
    width: 60%;
    border-radius: 22px;
    font-weight: bold;
}

#forgotLink {
    font-family: 'Muli Light', sans-serif;
    color: #2C2B7C;
    font-size: 10px;
    text-decoration: none;
}

.mainLogo {
    width: 25%;
}

.btnIcon {
    padding-left: 5%;
    font-size: 23px;
}

.password-input-blue {
    width: 90%;
    height: 100%;
    border: 0;
    color: #2c2b7c !important;
}

.password-input-blue:focus {
    border-color: transparent;
    box-shadow: none;
}
