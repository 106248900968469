.add-btn-row {
    height: 100px;
}

#add-manager-btn {
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 40px;
    width: 150px;
}

.manager-table-row {
    height: calc(100% - 100px);
}

.no-data {
    display: flex;
    justify-content: center;
    color: #2C2B7C;
    font-weight: 800;
    font-size: 40px;
    height: 100%;
}

#invite-info {
    font-size: 10px;
    color: #2C2B7C;
    text-align: center;
    width: 100%;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    display: flex;
}

#hiring-manager-btn {
    font-family: 'Poppins Bold', sans-serif;
    margin-top: 30px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 40px;
    width: 200px !important;
    text-transform: uppercase;
}

.add-location-link {
    font-size: 12px;
    color: #2C2B7C;
    margin-top: 20px;
}

.user-modal {
    width: 365px;
    border-radius: 28px;
}

.user-modal-close {
    color: #2c2b7c;
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
}

.user-modal-setting-wrapper {
    margin-top: 5px;
}

.make-admin-lbl {
    font-family: 'Poppins Bold', sans-serif;
    color: #2c2b7c;
    text-align: left;
    font-size: 12px;
}

.make-admin-info {
    font-family: 'Poppins Light', sans-serif;
    font-size: 12px;
    text-align: left;
    color: #2c2b7c;
}

#invite-info {
    font-family: 'Poppins Light', sans-serif;
    font-size: 10px;
    color: #2c2b7c;
}

#make-admin-checkbox {
    width: 20px;
    height: 20px;
}

#location-select {
    margin-top: 5px;
    color: #2c2b7c;
    font-family: 'Poppins Light', sans-serif;
    font-size: 12px;
}

.modal-input-green {
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 100%;
}

.error {
    margin-left: 5%;
}

