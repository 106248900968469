#root {
    height: 100vh !important;
    width: 100% !important;
}

.app {
    height: 100%;
    width: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.password-input-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-family: 'Muli Light', sans-serif;
    border: 2px solid #A3CF62 !important;
    color: #2C2B7C !important;
    border-radius: 19px;
    width: 100%;
    height: 38px;
    overflow: hidden;
}

.price-input-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-family: 'Muli Light', sans-serif;
    border: 2px solid #A3CF62 !important;
    border-radius: 19px;
    width: 80%;
    height: 38px;
    overflow: hidden;
}

.pass-toggle-icon path {
    fill: #2C2B7C;
}

.App-link {
    color: #61dafb;
}

.containerMain {
    background-color: #F3E7D0;
    width: 100%;
    height: 100%;
}

.main-body {
    position: relative;
    width: 85% !important;
    padding: 0;
}

.app-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.logoCol {
    background-color: #2C2B7C;
    height: 100%;
    width: 50%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .logoCol {
        display: none !important;
    }

    .loginRow {
        width: 100% !important;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.css-yk16xz-control, .css-1pahdxg-control {
    border-color: #A3CF62 !important;
    border-radius: 19px !important;
    border-width: 2px !important;
    width: 100%;
    box-shadow: none !important;
}

.css-1uccc91-singleValue {
    font-family: 'Poppins Light', sans-serif;
    font-size: 14px;
    color: #2c2b7c !important;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-g1d714-ValueContainer {
    width: 100%;
    height: 100%;

}

.btn-primary {
    border-color: transparent !important;
}

/*Form feedback text*/
.valid-feedback {
    color: #A3CF62 !important;
}

.invalid-feedback {
    text-align: right;
    margin: 0 !important;
}

.hourly-rate-input {
    width: 90% !important;
    height: 100% !important;
    border: 0 !important;
}

.hourly-rate-input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}

.dollar-prefix-icon path {
    fill: #2C2B7C;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.custom-jp-popover {
    color: #2c2b7c !important;
    font-family: 'Muli Regular', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
}
