.messagePage {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #F3E7D0;
    box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.75);
}

.sidebar-container {
    width: 25%;
    height: 100%;
}

.messaging-container {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 10px;
    width: 75%;
    height: 100%;
}

.chat__header {
    z-index: 1;
    height: 40px;
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 3px 3px 6px #00000029;
}

.chat-header-name {
    font: normal normal bold 12px/18px 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    text-transform: uppercase;
}

.chat-header-location {
    margin-left: 10px;
    font: normal normal normal 12px/18px 'Poppins Regular', sans-serif;
    letter-spacing: 0;
    color: #2C2B7C;
    text-transform: uppercase;
}

.chat__headerRight {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}

.chat__body {
    height: calc(100% - 110px);
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.chat__body::-webkit-scrollbar {
    display: none;
}

.message-content {
    font-family: 'Poppins Light', sans-serif;
    font-size: 12px;
    width: auto;
    max-width: 50%;
    margin: 15px 20px 0;
}

.message-content > .card-body {
    padding: .75rem 1rem;
}

.receiver {
    background-color: #FFFFFF;
    color: #252A2D;
    border-radius: 25px 25px 25px 5px;
}

.sender {
    background-color: #2C2B7C;
    border-radius: 25px 25px 5px 25px;
    color: #FFFFFF;
}

#receiver + #receiver .receiver {
    margin: 2px 20px 0;
    border-radius: 5px 18px 18px 5px;
}

#sender + #sender .sender {
    margin: 2px 20px 0;
    border-radius: 18px 5px 5px 18px;
}

.chat__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    border-top: 1px solid lightgray;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
}

.chat__footer > form {
    flex: 1;
    display: flex;
}

.message-input-wrapper {
    font-family: 'Muli Light', sans-serif;
    border: 2px solid #2C2B7C !important;
    color: #2C2B7C !important;
    border-radius: 19px;
    width: 70%;
    height: 40px;
    overflow: hidden;
}

.message-input-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chat__sendIcon {
    transform: rotate(-50deg) scale(1.5);
    margin-left: 25px;
}

.chat__footer > form > input {
    flex: 0.5;
    border-radius: 30px;
    padding: 10px;
    border: 3px solid #2C2B7C;
    margin-top: 10px;
    margin-left: 15px;
    height: 50px;
    outline: none;
}

::placeholder {
    color: #2C2B7C;
}

.chat__footer > .MulSvgIcon-root {
    padding: 10px;
    color: gray;
}

.chat__body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.messageCol {
    height: 100%;
    width: 70%;
    display: flex;
    flex-flow: column nowrap;
}

.messageCol > div:first-child {
    margin-top: auto;
}

.messageCol > div:last-child {
    padding-bottom: 10px;
}

.chatBody__message {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #F3E7D0;
    overflow-y: scroll;
}

.chatBody__message::-webkit-scrollbar {
    display: none;
}

.recipient-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.no-messages-found-txt {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 30px;
    line-height: 1;
    font-weight: 800;
    text-align: center;
    margin-top: 15px;
}

.no-messages-info-txt {
    font-family: 'Muli Regular', sans-serif;
    font-weight: 400;
    font-size: 20px;
}





