@font-face {
    font-family: 'Muli Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Muli Bold'), url(fonts/muli/Muli-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Muli Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Muli Regular'), url(fonts/muli/Muli.woff2) format('woff2');
}

@font-face {
    font-family: 'Muli Light';
    font-style: normal;
    font-weight: 300;
    src: local('Muli Light'), url(fonts/muli/Muli-Light.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'),
    url(fonts/poppins/poppins-bold-webfont.woff2) format('woff2'),
    url(fonts/poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins Semi Bold';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins Semi Bold'), url(fonts/poppins/poppins-semibold-webfont.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins Light';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), url(fonts/poppins/poppins-light-webfont.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    src: local('Poppins Regular'), url(fonts/poppins/poppins-regular-webfont.woff2) format('woff2');
}

@font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    src: local('Poppins Medium'), url(fonts/poppins/poppins-medium-webfont.woff2) format('woff2');
}
