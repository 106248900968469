.reset-main-container {
    height: 100%;
    width: 100%;
    text-align: center;
}

.reset-wrapper #forgotPasswordForm {
    width: 50%;
}

.reset-wrapper {
    width: 85%;
    padding: 30px 0;
}

.reset-header {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 0;
}

.reset-email {
    font-family: 'Muli Regular', sans-serif;
    color: #2C2B7C;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
}

.reset-info {
    font-family: 'Muli Regular', sans-serif;
    color: #2C2B7C;
    font-size: 16px;
    text-align: left;
}

.reset-input-green {
    border-color: #A3CF62 !important;
    color: #2c2b7c;
    font-size: 12px;
    border-radius: 19px;
    border-width: 2px;
    width: 230px;
    height: 38px;
}

#reset-submit-btn {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 150px;
}

