.cj-input-green {
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
}

.job-url-info {
    font-family: 'Poppins Light', sans-serif;
    font-size: 10px;
    color: #2c2b7c;
    margin: 15px 0;
    padding: 0 5px;
}
