.email-conf-main-wrapper {
    background-color: #F3E7D0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

.email-conf-logo {
    height: 100px;
    width: 200px;
}

.email-conf-info {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 28px;
    line-height: 1;
    text-transform: uppercase;
    margin: 50px 0;
}
