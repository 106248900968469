.stepper-input-green {
    font-family: 'Poppins Light', sans-serif;
    color: #2c2b7c !important;
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 100%;
    height: 38px;
    font-size: 14px;
}
