.applicant-info-modal .modal-content {
    height: 650px !important;
    width: 450px !important;
    padding: 0;
    overflow: hidden;
}

.applicant-top-banner {
    position: relative;
    height: 100px;
    width: 100%;
    background-image: url("../../../../../assets/banner.png");
    background-size: cover;
}

.applicant-pp-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90px;
    height: 90px;
    background-color: #FFFFFF;
    border-radius: 50%;
    top: 50%;
    left: 5%;
}

.applicant-pp {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.applicant-icon-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    right: 5%;
    width: 75px;
    height: 75px;
    background-color: #2C2B7C;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
    cursor: pointer;
    font-size: 28px;
}

.applicant-main-info-wrapper {
    margin-top: 40px;
    width: 100%;
    height: calc(100% - 140px);
    padding: 10px 5%;
    background-color: #FFFFFF;
    overflow-y: scroll;
}

.applicant-main-info-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.applicant-main-info-wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.applicant-main-info-wrapper::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}

#applicant-name {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 18px;
    color: #252A2D;
    text-transform: uppercase;
}

#applicant-desc {
    font-family: 'Muli Light', sans-serif;
    font-size: 14px;
    color: #252A2D;
}

.applicant-items-heading {
    margin-top: 10px;
    font-family: 'Poppins Bold', sans-serif;
    font-size: 14px;
    color: #2c2b7c;
    height: 20px;
    width: 100%;
    border-bottom: 1px solid #A3CF62;
}

.applicant-wh-item {
    margin-top: 5px;
    padding: 5px 0;
    height: auto;
    width: 100%;
    text-transform: uppercase;
}

.application-wh-title {
    word-break: break-word;
    hyphens: auto;
    font-family: 'Muli Bold', sans-serif;
    font-size: 20px;
    color: #2c2b7c;
}

.application-wh-info {
    font-family: 'Muli Light', sans-serif;
    font-size: 14px;
    color: #2c2b7c;
}

.application-skill-btn {
    display: inline;
    font-family: 'Muli Regular', sans-serif;
    background-color: #2C2B7C;
    border-radius: 19px;
    font-size: 10px;
    height: 30px;
    margin-right: 5px;
    padding: 3px 10px;
    white-space: nowrap;
    width: fit-content !important;
}

.not-provided {
    color: #2c2b7c;
}

#applicant-skill-carousel {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

#contact-info {
    font-family: 'Muli Light', sans-serif;
    font-size: 14px;
    color: #2c2b7c;
}

#applicant-skill-carousel::-webkit-scrollbar {
    width: 2px !important;
}

#applicant-skill-carousel::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}

.availability-day {
    width: 100px;
    font-family: 'Muli Regular', sans-serif;
    font-size: 12px;
    color: #2c2b7c;
}

.applicant-card-responsibility-lbl {
    width: 70%;
    font-family: 'Muli Light', sans-serif;
    font-size: 14px;
    color: #2c2b7c;
}

.applicant-card-responsibility-radio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 30%;
    font-family: 'Poppins Bold', sans-serif;
    font-size: 14px;
    color: #2c2b7c;
}

.applicant-card-responsibility-radio input:disabled {
    color: #2c2b7c;
    background-color: #2c2b7c;
}

