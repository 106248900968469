.jipe-btn:focus {
    box-shadow: 0 0 0 0.2rem #7FB7BE !important;
}

.jipe-btn:hover {
    background-color: #7FB7BE;
}

.jipe-btn {
    text-transform: uppercase;
    text-align: center;
}

.btn-loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #2c2b7c; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
