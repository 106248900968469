.add-question-custom-btn {
    font-family: 'Poppins Light', sans-serif;
    margin-top: 10px;
    background-color: #FFFFFF;
    color: grey;
    font-size: 12px;
    border: 2px solid #A3CF62;
    border-radius: 30px;
    min-height: 35px;
    width: 100%;
}

.active-add-question-btn {
    font-family: 'Muli Bold', sans-serif;
    background-color: #A3CF62;
    color: white;
    font-weight: bold;
}

.add-question-custom-btn:focus {
    outline: none;
}

#create-question-btn {
    font-family: 'Poppins Light', sans-serif;
    margin-top: 10px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 45px;
    width: 100%;
}

#add-question-btn {
    font-family: 'Poppins Light', sans-serif;
    margin-top: 15px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 45px;
    width: 100%;
}

.add-question-modal {

}

.qn-counter {
    width: 100%;
    line-height: 1;
    color: #2C2B7C;
    text-align: center;
    font-size: 12px;
}

.qn-input {
    border: 2px solid #A3CF62;
    color: #2C2B7C !important;
    border-radius: 24px;
    width: 100%;
    height: 115px !important;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    display: flex;
}

#no-qn-lbl {
    font-family: 'Muli Light', sans-serif;
    color: #2c2b7c;
    font-size: 20px;
}
