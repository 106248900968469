.description-input {
    font-family: 'Muli Regular', sans-serif;
    color: #2c2b7c !important;
    font-size: 14px;
    border: 2px solid #A3CF62;
    border-radius: 24px;
    width: 650px;
    height: 115px !important;
}

.invalid-input {
    border-color: red;
}

.description-counter {
    font-family: 'Muli Regular', sans-serif;
    width: 100%;
    line-height: 1;
    color: #2C2B7C; 
    text-align: center;
    font-size: 14px;
}

.max-counter {
    color: red;
}

