#applicationCard {
    flex: 0 0 auto;
    height: 575px;
    width: 375px;
    border-radius: 18px;
    background-color: white;
}

#bannerCard {
    height: 105px;
}

#bannerImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.ac-pp-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90px;
    height: 90px;
    background-color: #FFFFFF;
    border-radius: 50%;
    top: 10%;
    left: 5%;
}

#bannerEllipse {
    position: absolute;
    width: 75px;
    height: 75px;
    background-color: #2C2B7C;
    border-radius: 50%;
    border: 5px solid white;
    margin-top: 17%;
    margin-left: 75%;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 1;
}

#applicationTitle {
    padding: 0 10px;
    font-size: 18px;
    font-weight: bold;
}

#applicationSub {
    font-size: 14px;
    font-weight: lighter;
    letter-spacing: 1px;
    color: #252A2D;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    padding: 0 10px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 50px;
}

#experienceInfo {
    display: flex;
    flex-flow: column nowrap;
    color: #2C2B7C;
    text-align: center;
    padding-top: 15px;
}

#experienceInfo::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

#experienceInfo::-webkit-scrollbar-track {
    background: #888;
    border-radius: 10px;
}

#experienceInfo::-webkit-scrollbar-thumb {
    background: #2C2B7C;
    border-radius: 10px;
}

.jobPlace {
    font-weight: bold;
    font-size: 20px;
}

.jobDuration {
    font-weight: lighter;
    font-size: 14px;
}

#showSpecialities {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0;
    color: white;
    overflow-x: auto;
}


.applicant-card-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.applicant-card-body::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.applicant-card-body::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}


.no-work-history {
    font-size: 20px;
    color: #bfbfbf !important
}

.exclamation {
    margin-left: auto;
    margin-right: auto;
}

#speciality {
    background-color: #2C2B7C;
    border-radius: 19px;
    font-size: 8px;
    white-space: nowrap;
}

#speciality:not(:last-child) {
    margin-right: 5px;
}

.applicant-card-body {
    overflow-y: auto;
    height: 80%;
    padding-left: 10px;
}
