#manager-table {
    height: 100%;
    width: 100%;
}

.manager-table-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-transform: uppercase;
    color: #2C2B7C;
    font-weight: bold;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #A3CF62;
}

.manager-table-body {
    height: calc(100% - 65px);
    width: 100%;
    overflow-y: auto;
}

.manager-table-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.manager-table-body::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.manager-table-body::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-image: -webkit-gradient(linear,
    right bottom,
    left top,
    color-stop(2.90, rgb(122, 153, 217)),
    color-stop(0.90, rgb(73, 125, 189)),
    color-stop(3.90, #2C2B7C));
}

.table-lbl {
    font-size: 14px;
    color: #2C2B7C;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.manager-table-tr:hover {
    border: 3px solid #2C2B7C;
}

.manager-dropdown-menu {
    width: 210px;
    height: 95px;
}

.manager-table-ellipsis {
    display: none;
    height: 25px;
    width: 25px;
}

.manager-table-ellipsis:after {
    content: none;
}

.manager-table-tr:hover .manager-table-ellipsis {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    background-color: #2C2B7C !important;
}

.manager-table-ellipsis path {
    fill: white;
}

.show > .btn-primary.dropdown-toggle {
    background: transparent;
    border: none;
    box-shadow: none !important;
}

.manager-table-ellipsis, .manager-table-ellipsis:hover {
    background: transparent;
    border: none;
}

.manager-dropdown-item {
    height: 35px;
    width: 100% !important;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: black;
    font-size: 14px;
}
