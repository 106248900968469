.cropper-modal {
    max-width: 700px;
}

.cropper-modal .modal-content {
    padding: 10px;
    border-radius: 19px;
    height: 580px !important;
}

.cropper-modal .modal-body-container {
    margin-top: 40px;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-y: scroll;
}

.modal-close-fab {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #2c2b7c;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
}

#selectImageButton {
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 100%;
    margin-top: 20px;
}

img {
    max-width: 100%;
}

.ReactCrop {
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
}
