.package-confirmation-modal {
    max-width: 900px;
}

.package-confirmation-modal .modal-content {
    padding: 10px;
    border-radius: 19px;
    height: 500px !important;
}

.package-confirmation-modal .modal-body-container {
    margin-top: 40px;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-y: scroll;
}

.modal-close-fab {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #2c2b7c;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
}

#promo-btn {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    background-color: white;
    border: 1px solid #2c2b7c;
    border-radius: 33px;
    width: 180px;
    height: 40px;
    margin-top: 30px;
    font-weight: bold;
}


.subscription-list-lbl {
    font-family: 'Poppins Light', sans-serif;
    color: #2c2b7c;
    font-size: 19px;
}

.plan-per-list-lbl {
    font-family: 'Poppins Light', sans-serif;
    color: #EE6C4D;
    font-size: 15px;
}

.pricing-plan-per-list-lbl {
    font-family: 'Poppins Medium', sans-serif;
    text-transform: uppercase;
    color: #EE6C4D;
    font-size: 15px;
}

.plan-sub-header {
    display: flex;
    flex-flow: column nowrap;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    font-family: 'Poppins Medium', sans-serif;
}

.plan-card {
    cursor: pointer;
    text-align: center;
    background-color: #FFFFFF;
    color: #2C2B7C;
    width: calc(100% / 4);
}

.plan-card:last-child {
    border: none !important;
}

.plan-header {
    letter-spacing: 0px;
    font-family: 'Poppins Bold', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.pricing-plan-offer-tag {
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Poppins Semi Bold', sans-serif;
    color: #A3CF62;
    text-align: center;
}

.pricing-plan-info {
    font-size: 14px;
    font-family: 'Poppins Regular', sans-serif;
    color: #2C2B7C;
    text-align: center;
}

.pricing-plan-title {
    font-family: 'Poppins Semi Bold', sans-serif;
    font-size: 24px;
    color: #2C2B7C;
}

