.header-navbar-container {
    width: 100%;
    height: 55px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #2C2B7C;
}

.header-logo {
    width: 100px;
    height: 40px;
    margin-left: 30px;
}

.header-logout-icon {
    font-size: 24px;
    color: white;
    cursor: pointer;
    margin-right: 2rem;
}

.header-body-container {
    width: 100%;
    height: calc(100% - 55px);
    overflow-y: auto;
    background-color: #F3E7D0;
}

.header-footer-image {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 110px;
    width: 100%;
}
