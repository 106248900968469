.main-container {
    height: 100%;
    width: 100%;
    text-align: center;
}

.containerRow {
    display: flex;
    flex-flow: row wrap;
    background-color: rebeccapurple;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.mainLogo {
    width: 25%;
}

.reset-row {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #F3E7D0;
    height: 100%;
    width: 50%;
}

.resend-email-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 425px;
    border-radius: 30px;
    z-index: 10;
}

.resend-title {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 18px;
    color: #2c2b7c;
    text-transform: uppercase;
}

.resend-label {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 16px;
    color: black;
    width: 95%;
    margin: 0 auto;
}

#resend-email-btn {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #2C2B7C;
}

#back-btn {
    font-family: 'Poppins Regular', sans-serif;
    position: absolute;
    bottom: 5%;
    color: #2C2B7C;
    font-size: 16px;
}

@media only screen and (max-width: 1200px) {
    .reset-row {
        width: 100% !important;
    }
}
