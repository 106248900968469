.breadcrumb-container {
    height: 50px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 6px #00000029;
    position: sticky;
    top: 0;
    z-index: 1;
}

.no-data {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    color: #2C2B7C;
    font-weight: 800;
    font-size: 40px;
    width: 100%;
}


.spinner-wrapper {
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
}

.spinner {
    display: flex;
}

.jipe-breadcrumb {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: #2C2B7C;
    font-weight: bold;
    font-size: 14px;
    position: sticky;
    top: 0;
    text-transform: uppercase;
}

.jipe-breadcrumb-item + .jipe-breadcrumb-item {
    padding-left: 0.5rem;
    display: flex;
    align-items: center
}

.jipe-breadcrumb-arrow {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center
}

.jipe-breadcrumb-item:hover {
    cursor: pointer;
    border-bottom: 1px solid #2C2B7C;
}

.jipe-breadcrumb-item + .jipe-breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #2C2B7C;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
    /* content: '>'; */
}

#position-table-row {
    background-color: transparent;
}

#position-lbl {
    font-family: "Muli Bold", sans-serif;
    color: #2C2B7C;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

#loc-create-opening-btn {
    width: 170px;
    height: 40px;
    background-color: #2C2B7C;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

#location-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.modal-content {
    height: 100% !important;
    width: 100% !important;
}

.modal-body-container {
    width: 100%;
    height: 100%;
    padding: 15px;
}

.question-modal {
    width: 330px !important;
    border-radius: 18px !important;
}

.custom-qn-lbl {
    font-size: 12px;
    color: black;
}

.textarea-input-green {
    border-color: #A3CF62 !important;
    border-radius: 19px;
    border-width: 2px;
    width: 280px;
    height: 90px;
}

.question-modal-btn {
    margin-top: 15px;
    padding: 0 10px;
    background-color: #2C2B7C;
    border: 0;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    height: 40px;
    width: 120px;
}

.active-modal-btn {
    background-color: #A3CF62;
    color: white;
    font-weight: bold;
}

.plan-tracker-container {
    height: 100%;
    width: 200px;
}

.tracker-wrapper {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Poppins Medium', sans-serif;
    width: 100%;
    height: 90%;
    border-radius: 0 0 18px 18px;
}

.location-see-info-span {
    font-size: 8px;
    font-family: "Poppins Light", sans-serif;
    color: #EE6C4D;
    text-transform: lowercase;
    line-height: 1;
    padding: 5px;
    margin-top: 2px;
}

.location-see-info-span:hover {
    text-decoration: underline;
    cursor: pointer;
}
