.deactivate-confirmation-modal {
    max-width: 500px;
}

.deactivate-confirmation-modal .modal-content {
    padding: 10px;
    border-radius: 19px;
    height: 230px !important;
}

.deactivate-confirmation-modal .modal-body-container {
    margin-top: 40px;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-y: scroll;
}

.modal-close-fab {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #2c2b7c;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
}

.deactivation-info-display {
    font-family: 'Poppins Semi Bold', sans-serif;
    font-size: 18px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #2c2b7c;
}

#deactivate-location-btn {
    font-family: 'Poppins Bold', sans-serif;
    color: #FFFFFF;
    background-color: #2C2B7C;
    border-radius: 22px;
    width: 300px;
    height: 45px;
    margin-bottom: 25px;
}
