.preview-banner {
    position: relative;
    width: 100%;
    height: 105px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 19px;
}

.profile-preview-modal .modal-content {
    padding: 0;
    border-radius: 19px;
}

.profile-preview-modal .modal-body-container {
    margin-top: 40px;
    margin-bottom: 15px;
    overflow-y: auto;
}

.profile-preview-modal {
    width: 370px;
}

.time-preview-modal {
    max-width: 335px;
}

.time-preview-modal .modal-content {
    padding: 0;
    border-radius: 19px;
    height: 350px !important;
    box-shadow: 0px 0px 30px 3px #000;
}

.hoverable:hover {
    zoom: 1.05;
}

.time-preview-modal .modal-body-container {
    margin-top: 40px;
    margin-bottom: 15px;
    overflow-y: auto;
}

.modal-close-fab {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #2c2b7c;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
}

.pre-profile-picture {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    position: absolute;
    bottom: -40px;
    left: 15px;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.pre-job-title {
    font-family: 'Poppins Bold', sans-serif;
    text-align: left;
    font-size: 18px;
}

.pre-business-owner {
    font-family: 'Poppins Light', sans-serif;
    text-align: left;
    font-size: 14px;
    width: 100%;
    word-break: break-word;
    hyphens: auto;
}

.pre-business-cards-wrapper {
    height: 100px;
}

.pre-business-info-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
    width: 95px;
    height: 95px;
    border-radius: 25px;
}

.pre-business-light {
    font-family: 'Poppins Light', sans-serif;
}

#pre-business-bold {
    font-family: 'Poppins Bold', sans-serif;
}

#pre-business-salary {
    font-family: 'Poppins Medium', sans-serif;
    font-size: 21px;
}

.pre-responsibility-card {
    margin-top: 15px;
    padding: 10px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
}

.pre-responsibility-listing {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #A3CF62;
}

.responsibility-item {
    color: black;
    font-family: 'Poppins Light', sans-serif;
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.pre-about-us {
    margin-top: 20px;
}

#pre-aboutus-title {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 14px;
    color: black;
    text-align: left;
    text-transform: uppercase;
}

#pre-aboutus-info {
    font-family: 'Poppins Light', sans-serif;
    font-size: 12px;
    text-align: left;
}

#pre-social-url-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    height: 50px;
    margin-top: 15px;
}

.pre-social-icon-container g {
    fill: #2c2b7c;
}

#pre-photo-video-wrapper {
    margin-top: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
}

.pre-photo-div {
    width: 150px;
    height: 120px;
    margin-top: 10px;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.pre-video-div {
    width: 100%;
    height: 120px;
    margin-top: 10px;
    border-radius: 16px;
    border: 1px solid #707070;
    background-image: url("../../assets/photo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hours-modal-body-container {
    padding: 20px 10px;
    overflow-y: auto;
}

#time-modal-title {
    font-size: 18px;
    font-family: 'Poppins Bold', sans-serif;
    color: #2c2b7c;
    text-align: center;
    margin-top: 50px;
}

#time-modal-availability-lbl {
    font-size: 14px;
    color: #2c2b7c;
    font-family: 'Poppins Light', sans-serif;
}

.time-modal-days {
    font-size: 14px;
    font-family: 'Poppins Bold', sans-serif;
    color: #2c2b7c;
}

.time-modal-times {
    display: flex;
    flex-flow: column nowrap;
    font-size: 13px;
    color: #2c2b7c;
    font-family: 'Poppins Light', sans-serif;
}
