.applicationRow {
    height: 100px;
}

.application-search-input {
    width: 250px;
    height: 40px;
    border: 2px solid #A3CF62;
    border-radius: 19px;
    padding: 10px;
    outline: none;
}

.application-name {
    margin-left: 10px;
    width: 250px;
}

#application-table-row {
    height: calc(100% - 100px);
    background-color: transparent;
}

.application-job {
    width: 250px;
}

.status-filter {
    font-family: "Muli Bold", sans-serif;
    font-size: 14px;
    color: #2C2B7C;
}

