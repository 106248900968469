.main-wrapper {
    background-color: #F3E7D0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.content-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.error-text-container {
    padding-left: 20px;
    height: 150px;
    border-left: solid 2px greenyellow;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.error-one-day-txt {
    font-family: 'Poppins Bold', sans-serif;
    color: #2C2B7C;
    font-size: 42px;
    line-height: 1;
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
}

.error-info-txt {
    font-family: 'Muli Regular', sans-serif;
    font-weight: 400;
    font-size: 20px;
}

#home-btn {
    background-color: #2C2B7C;
    width: 150px;
    height: 45px;
    border-radius: 22px;
    font-weight: bold;
    align-self: center;
}
